import { useState } from "react";
import useSound from "use-sound";
import ziziDefaultImage from "./zizi_default.jpg";
import ziziAltImage from "./zizi_onclick.jpg";
import zi from "./click.wav";
import zizi from "./zizi_full.wav";
import "./App.css";

function App() {
  const [image, setImage] = useState(ziziDefaultImage);
  const [imgClass, setImgClass] = useState("App-logo-d");
  const [isPlaying, setPlaying] = useState(false);
  const [counter, setCounter] = useState(1);
  const [playZi] = useSound(zi);
  const [playZiZi] = useSound(zizi);
  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  const handelClick = () => {
    if (!isPlaying) {
      var sleepTime = 500;
      setCounter(counter + 1);
      setImage(ziziAltImage);
      setPlaying(true);
      if ((counter % 10) == 0) {
        sleepTime = 8000;
        setImgClass("App-logo");
        playZiZi();
      } else {
        playZi();
      }
      sleep(sleepTime).then(() => {
        setImage(ziziDefaultImage);
        setImgClass("App-logo-d");
        setPlaying(false);
      });
    }
  };
  return (
    <div className="App main" onClick={handelClick}>
      <p className="centered-txt">{counter}</p>
      <img src={image} className={imgClass} alt="logo" />
    </div>
  );
}

export default App;
